export function fetchEmailFromLocalStorage(): string | null {
  const email = localStorage.getItem('email')

  if (email) {
    return email
  }

  return null
}

export function removeEmailFromLocalStorage(): void {
  localStorage.removeItem('email')
}
