import { LoginState } from 'contexts/LoginProvider'
import { useLoginContext } from 'hooks/useLoginContext'

import { ProviderNotSetup } from './ProviderNotSetup'

export function ErrorStatus() {
  const { status } = useLoginContext()

  if (status === LoginState.AccountSuspended) {
    return (
      <p className="mt-6 rounded-md border border-red-200 bg-red-50 p-3 text-sm text-red-600 dark:border-red-400 dark:bg-red-200 dark:text-red-800">
        <span className="mb-2 block font-medium">Your account is currently suspended.</span>
        <span>
          We understand credentialing is important to you, so if you think this was a mistake, please reach out to
          Modivcare&apos;s Credentialing Services Team or your Provider Relations Manager to restore your account.
        </span>
      </p>
    )
  } else if (status === LoginState.ProviderNotSetup) {
    return <ProviderNotSetup />
  }

  return null
}
