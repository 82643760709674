import { LoginContext } from 'components/Login/LoginContext'
import { fetchEmailFromLocalStorage } from 'components/Login/utilities'
import { useAnalytics } from 'hooks/useAnalytics'
import { useFlagsmith } from 'hooks/useFlagsmith'
import { setAccount, setGlobalError } from 'internal/redux'
import { setActiveWorkspace } from 'internal/redux/slices/workspace'
import { checkDeviceLogin, getProfile } from 'lib/api'
import { resendProviderInvite } from 'lib/api/admin'
import { post } from 'lib/api/req'
import { useRouter } from 'next/router'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { shouldCheckup } from '../helpers/shouldCheckup'

type Data = {
  email: string
  token: string
}

export enum LoginState {
  Email = 'email',
  InvalidEmail = 'invalidEmail',
  ProviderNotSetup = 'providerNotSetup',
  MagicLink = 'magicLink',
  DeviceLogin = 'deviceLogin',
  ExpiredDeviceLogin = 'expiredDeviceLogin',
  InvalidMagicLink = 'invalidMagicLink',
  LoginCode = 'loginCode',
  InvalidLoginCode = 'invalidLoginCode',
  AccountSuspended = 'accountSuspended',
}

export type LoginContextType = {
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  encodedToken: string
  register: UseFormRegister<{
    email: string
    token: string
  }>
  submit: (e?: React.BaseSyntheticEvent<object, any, any>) => Promise<void>
  values: {
    email: string
    token: string
  }
  setValue: UseFormSetValue<{
    email: string
    token: string
  }>
  requestLogin: (email: any, resend?: boolean, skipDeviceLogin?: boolean) => Promise<void>
  handleLogin: (data: Data) => Promise<void>
  status: LoginState
  setStatus: React.Dispatch<React.SetStateAction<LoginState>>

  emailReadonly?: boolean
  shouldSaveEmail: boolean
  setShouldSaveEmail: React.Dispatch<React.SetStateAction<boolean>>
}

export function LoginProvider({
  email,
  emailReadonly,
  children,
}: {
  email?: string
  emailReadonly?: boolean
  children: React.ReactNode
}) {
  const dispatch = useDispatch()
  const router = useRouter()
  const analytics = useAnalytics()
  const flagsmith = useFlagsmith()
  const { token, next } = router.query
  const encodedToken = token as string
  const [status, setStatus] = useState<LoginState>(LoginState.Email)
  const [deviceLoginToken, setDeviceLoginToken] = useState<string | null>(null)
  const [shouldSaveEmail, setShouldSaveEmail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: { email, token: '' },
  })

  const values = watch()
  const url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/auth/login`

  const requestLogin = async (email: string, resend = false, skipDeviceLogin = false) => {
    setIsLoading(true)

    await post('/v1/auth/request', { email, next, skipDeviceLogin })
      .then(async r => {
        if (r.status === 403) {
          setStatus(LoginState.AccountSuspended)
          return
        }
        if (r.status === 400) {
          r.json()
            .then(body => {
              if (body?.code === 'PROVIDER_NOT_SETUP') {
                setStatus(LoginState.ProviderNotSetup)
                return
              } else {
                setStatus(LoginState.InvalidEmail)
                return
              }
            })
            .catch(() => {
              setStatus(LoginState.InvalidEmail)
            })

          return
        }
        if (r.status !== 200) {
          toast.error('Something went wrong. Please try again later')
          return
        }

        if (shouldSaveEmail) {
          localStorage.setItem('email', email)
        }

        if (resend) {
          if (skipDeviceLogin) {
            toast.info('Another email has been sent')
          } else {
            toast.info('Another notification has been sent')
          }
        }

        analytics?.track('Login - Requested Token', {
          email,
        })

        r.json()
          .then(body => {
            if (body.deviceLoginToken) {
              setDeviceLoginToken(body.deviceLoginToken)
              setStatus(LoginState.DeviceLogin)
              return
            }
          })
          .catch(() => {})

        setStatus(LoginState.MagicLink)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const sendProviderEmail = async () => {
    try {
      const res = await resendProviderInvite({ email: values.email })
      if (res.status === 200) {
        toast.info('Successfully sent instruction email')
        setStatus(LoginState.Email)
      } else {
        toast.error('Something went wrong. Please try again later')
      }
    } catch (_) {
      toast.error('Something went wrong. Please try again later')
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async data => {
    if (isLoading) {
      return
    }

    if (status === LoginState.MagicLink || status === LoginState.InvalidMagicLink) {
      setStatus(LoginState.LoginCode)
      return
    }

    setIsLoading(true)
    data.email = data.email?.trim()

    if (status === LoginState.Email || status === LoginState.InvalidEmail) {
      await requestLogin(data.email)
      return
    } else if (status === LoginState.ProviderNotSetup) {
      await sendProviderEmail()
      return
    }

    data.token = new Array(6)
      .fill(0)
      .map((_, i) => {
        const id = `token${i + 1}`
        const input = document.getElementById(id) as HTMLInputElement
        return input.value
      })
      .join('')

    handleLogin(data)
  }

  const handleLogin = async (data: Data) => {
    try {
      const logInRes = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data),
      })

      if (logInRes.status === 400) {
        if (encodedToken && status === LoginState.Email) {
          setValue('email', data.email)
          setStatus(LoginState.InvalidMagicLink)
        } else {
          setStatus(LoginState.InvalidLoginCode)
        }

        return
      }

      if (logInRes.status === 403) {
        setStatus(LoginState.AccountSuspended)

        return
      }

      const profileRes = await getProfile()
      if (profileRes.status === 502) {
        dispatch(setGlobalError())
        return
      } else {
        const acc = await profileRes.json()
        if (acc.error) {
          dispatch(setAccount(null))
          return
        }
        analytics?.register({
          accountId: acc?.id,
          roles: acc?.roles.join(', '),
          market: acc?.provider?.market?.name || '',
        })
        flagsmith.identify(acc.id, {
          providerId: acc.providerId,
          market: acc.provider?.market?.name || '',
        })

        dispatch(setAccount(acc))

        if (acc.roles.includes('broker') && acc.workspace) {
          dispatch(setActiveWorkspace(acc.workspace))
        }

        analytics?.track('Login', {
          email: data.email,
          experienceVersion: window.localStorage.getItem('topBar') === 'show' ? 'new' : 'old',
          isNotificationsEnabled: acc?.isNotificationsEnabled,
        })

        if (window.location.pathname === '/login') {
          if (shouldCheckup(acc)) {
            router.push(('/providers/checkup?next=' + (router.query.next || '/')) as string)
            return
          }

          router.replace((router.query.next as string) || '/')
        }
      }
    } catch {
      dispatch(setGlobalError())
      return
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (email) return
    const emailFromLocalStorage = fetchEmailFromLocalStorage()

    if (emailFromLocalStorage) {
      setShouldSaveEmail(true)
      setValue('email', emailFromLocalStorage)
    }
  }, [email, setValue, setShouldSaveEmail])

  useEffect(() => {
    if (!deviceLoginToken || status !== LoginState.DeviceLogin) return

    const time = Date.now()
    const to = window.setInterval(() => {
      if (Date.now() - time > 1000 * 60) {
        setStatus(LoginState.ExpiredDeviceLogin)
        setDeviceLoginToken(null)
        clearTimeout(to)
      }

      checkDeviceLogin(deviceLoginToken).then(async r => {
        if (!r.ok) {
          return
        }

        const profileRes = await getProfile()
        if (profileRes.status === 502) {
          dispatch(setGlobalError())
        } else {
          const acc = await profileRes.json()

          if (acc.error) {
            dispatch(setAccount(null))
          } else {
            analytics?.register({
              accountId: acc?.id,
              roles: acc?.roles.join(', '),
            })

            flagsmith.identify(acc.id, {
              providerId: acc.providerId,
            })

            dispatch(setAccount(acc))

            analytics?.track('Login', {
              email: acc.email,
              experienceVersion: window.localStorage.getItem('topBar') === 'show' ? 'new' : 'old',
              isNotificationsEnabled: acc?.isNotificationsEnabled,
            })
          }
        }

        setDeviceLoginToken(null)

        if (window.location.pathname === '/login') {
          router.replace((router.query.next as string) || '/')
        }
      })
    }, 1000)

    return () => {
      clearTimeout(to)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceLoginToken, analytics, dispatch, flagsmith, status])

  const submit = handleSubmit(onSubmit)

  return (
    <LoginContext.Provider
      value={{
        isLoading,
        setIsLoading,
        encodedToken,
        register,
        submit,
        values,
        requestLogin,
        handleLogin,
        status,
        setValue,
        setStatus,
        emailReadonly,
        shouldSaveEmail,
        setShouldSaveEmail,
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}
