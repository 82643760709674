import { Button } from 'components/Button'
import { useLoginContext } from 'hooks/useLoginContext'

export function ProviderNotSetup() {
  const { isLoading } = useLoginContext()

  return (
    <div>
      <p className="mt-6 rounded-md border border-yellow-200 bg-yellow-100 p-3 text-sm text-yellow-800 dark:border-yellow-400 dark:bg-yellow-200 dark:text-yellow-800">
        <span className="mb-2 block font-medium">Activate your provider account</span>
        <span>Click the button below to receive an email with instructions.</span>
      </p>
      <Button className="mt-6" type="submit" disabled={isLoading} variant="primary" block>
        Send email
      </Button>
    </div>
  )
}
