import { Banner } from 'components/Banner'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Text } from 'components/Text'
import { Title } from 'components/Title'
import { LoginState } from 'contexts/LoginProvider'
import { useLoginContext } from 'hooks/useLoginContext'
import { HiExclamationTriangle } from 'react-icons/hi2'

export function DeviceLogin() {
  const { status, values, requestLogin } = useLoginContext()

  return (
    <Box className="flex flex-col items-center justify-center gap-4">
      <Title center type="h1" weight="bold" size="2xl" ariaLabel="Check your device">
        Check your device
      </Title>
      {status === LoginState.DeviceLogin ? (
        <Text variant="light" className="text-center">
          We sent a login notification to your device. Approve it to log in to your account.
        </Text>
      ) : (
        <Banner
          title="Login request has timed out"
          msg="Request a new notification to log in to your account."
          icon={HiExclamationTriangle}
          variant="warning"
        />
      )}
      <Box className="flex w-full flex-col gap-2">
        <Button onClick={() => requestLogin(values.email, true, false)} block variant="primary">
          Get another notification
        </Button>
        <Button onClick={() => requestLogin(values.email, false, true)} block>
          Get an email instead
        </Button>
      </Box>
    </Box>
  )
}
