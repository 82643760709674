import { LoginState } from 'contexts/LoginProvider'
import { useLoginContext } from 'hooks/useLoginContext'

import { DeviceLogin } from './DeviceLogin'
import { Email } from './Email'
import { LoginCode } from './LoginCode'
import { MagicLink } from './MagicLink'

export function LoginForm() {
  const { status } = useLoginContext()

  if (status === LoginState.DeviceLogin || status === LoginState.ExpiredDeviceLogin) {
    return <DeviceLogin />
  } else if (status === LoginState.MagicLink || status === LoginState.InvalidMagicLink) {
    return <MagicLink />
  } else if (status === LoginState.LoginCode || status === LoginState.InvalidLoginCode) {
    return <LoginCode />
  }

  return <Email />
}
