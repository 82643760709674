import { differenceInDays } from 'date-fns'
import { Account } from 'types'

export function shouldCheckup(account: Account) {
  if (!account.providerId || !account.provider?.traits) return false

  const now = Date.now()
  const threshold = 90
  const fn = differenceInDays

  if (isNaN(account.provider.traits.lastCheckupAt) || isNaN(account.provider.traits.checkupSkippedAt)) {
    return true
  }

  if (account.provider.traits.checkupSkippedAt && fn(now, account.provider.traits.checkupSkippedAt) < threshold) {
    return false
  }

  if (account.provider.traits.lastCheckupAt && fn(now, account.provider.traits.lastCheckupAt) < threshold) {
    return false
  }

  return true
}
