import { Button } from 'components/Button'
import { CheckBox } from 'components/CheckBox'
import { InputGroup } from 'components/InputGroup'
import { Text } from 'components/Text'
import { Title } from 'components/Title'
import { LoginState } from 'contexts/LoginProvider'
import { useLoginContext } from 'hooks/useLoginContext'
import { ChangeEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HiArrowRight } from 'react-icons/hi'

import { ErrorStatus } from './ErrorStatus'
import { fetchEmailFromLocalStorage, removeEmailFromLocalStorage } from './utilities'

const shouldShowErrorStatus = (status: LoginState) =>
  [LoginState.AccountSuspended, LoginState.ProviderNotSetup].includes(status)

export function Email() {
  const { t } = useTranslation()
  const { register, isLoading, emailReadonly, status, setStatus, setValue, shouldSaveEmail, setShouldSaveEmail } =
    useLoginContext()

  const toggleRememberEmail = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked === true) {
      setShouldSaveEmail(true)
    } else {
      setShouldSaveEmail(false)
      removeEmailFromLocalStorage()
    }
  }

  useEffect(() => {
    const emailFromLocalStorage = fetchEmailFromLocalStorage()

    if (emailFromLocalStorage) {
      setShouldSaveEmail(true)
      setValue('email', emailFromLocalStorage)
    }
  }, [setValue, setShouldSaveEmail])

  const invalidEmail = status === LoginState.InvalidEmail

  return (
    <div className="pb-6" data-testid="login-email">
      <div className="flex flex-col items-center justify-center">
        <Title center type="h1" weight="bold" size="2xl" className="mb-6" ariaLabel="sign in message">
          {t('signInMsg')}
        </Title>
      </div>
      <div className="mt-8">
        <InputGroup
          label={t('email')}
          id="email"
          placeholder=""
          error={invalidEmail && t('emailFormatErr')}
          onFocus={() => (invalidEmail ? setStatus(LoginState.Email) : null)}
          disabled={isLoading}
          readOnly={emailReadonly}
          {...register('email')}
          required
        />
        <div className="mt-2 inline-flex items-center">
          <label className="inline-flex w-full cursor-pointer items-center py-2">
            <CheckBox data-testid="rememberEmail" checked={shouldSaveEmail} onChange={toggleRememberEmail} />
            <Text className="ml-2" weight="normal" size="sm">
              Remember Email
            </Text>
          </label>
        </div>
      </div>
      {shouldShowErrorStatus(status) ? (
        <ErrorStatus />
      ) : (
        <Button type="submit" className="mt-6" rightIcon={HiArrowRight} disabled={isLoading} variant="primary" block>
          {t('next')}
        </Button>
      )}
    </div>
  )
}
